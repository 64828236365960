import React from 'react';
import { Link } from 'gatsby';
import Helmet from 'react-helmet';
import Layout from '../components/layout';
import BannerProfessionalJournals from '../components/BannerProfessionalJournals.js';
import JSONDataProfession from '../content/professionalgroup.json';

const ProfessionalJournals = (props) => (
  <Layout>
    <Helmet>
      <title>Professional Journals - Mills Publishing Inc.</title>
      <meta
        name="description"
        content="Our professional publications give your business access to established, niche audiences, including members of the Utah Dental Association, Utah Medical Association, and the Salt Lake Board of Realtors."
      />
    </Helmet>

    <BannerProfessionalJournals />

    <div id="main">
      <section id="one">
        <div className="inner">
          <header className="major">
            <h2>Specialized Markets for your Specific Needs</h2>
          </header>
          <p>
            Each of these journals is a regular publication for a specialized
            group or board. Whether it’s one of the medical market, the realty
            business, the dental industry, or financial matters, you’re sure to
            find something that will elevate your brand, increase your sales,
            and strengthen and elevate your brand.
          </p>
        </div>
      </section>
      <section id="two" className="spotlights">
        {JSONDataProfession.professionalgroups.map((data, index) => {
          return (
            <section key={index}>
              <Link to={data.link} className="image">
                <img src={data.image} alt="" />
              </Link>
              <div className="content">
                <div className="inner">
                  <header className="major">
                    <h3>{data.title}</h3>
                  </header>
                  <p dangerouslySetInnerHTML={{ __html: data.desc }}></p>
                  <ul className="actions">
                    <li>
                      <Link to={data.link} className="button">
                        Learn more
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </section>
          );
        })}
      </section>
    </div>
  </Layout>
);

export default ProfessionalJournals;
