import React from 'react';

const BannerProfessionalJournals = (props) => (
  <section id="banner" className="style2">
    <div className="inner">
      <header className="major">
        <h1>Professional Journals</h1>
      </header>
      <div className="content">
        <p>
          Our professional publications give your business access to
          established, niche audiences, including members of the Utah Dental
          Association, Utah Medical Association, NACVA, and the Salt Lake Board
          of Realtors. Spend your advertising dollars with confidence—know that
          an ad in our trade journals will send a message to the targeted market
          of your choice.
        </p>
        <ul className="actions">
          <li>
            <a href="/advertising-specifications" className="button special">
              Professional Journal Ad Specs
            </a>
          </li>
        </ul>
      </div>
    </div>
  </section>
);

export default BannerProfessionalJournals;
